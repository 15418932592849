<template>
  <div class="">
    <el-card shadow="never">
      <el-button type="primary" @click="dialogVisible = true"
        >添加视频</el-button
      >
      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column align="center" type="index" label="序号" />
          <el-table-column prop="userName" align="center" label="封面" />
          <el-table-column prop="userName" align="center" label="视频标题" />
          <el-table-column prop="userName" align="center" label="地址" />
          <el-table-column prop="userName" align="center" label="上传者" />
          <el-table-column prop="userName" align="center" label="上传时间" />
          <el-table-column prop="userName" align="center" label="播放量PV " />
          <el-table-column prop="userName" align="center" label="操作" />
        </template>
      </commonTable>
    </el-card>
    <el-dialog
      title="添加视频"
      :visible.sync="dialogVisible"
      width="50%"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="78px"
      >
        <el-form-item label="视频名称" prop="title">
          <el-input
            v-model="ruleForm.options"
            placeholder="请输入视频名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="视频封面" prop="title">
          <el-upload
            :class="{
              'avatar-uploader': true,
              'avatar-coverImg': true,
              disabled: uploadDisabled,
            }"
            :action="$store.state.uploadingUrl"
            list-type="picture-card"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessCoverImg"
            :on-remove="handleRemove"
            :file-list="fileList"
            :limit="1"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              *格式png、jpg，大小不超过2MB。
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="视频简介" prop="title">
          <el-input
            type="textarea"
            :rows="3"
            v-model="ruleForm.options"
            placeholder="请输入视频简介"
          ></el-input>
        </el-form-item>
        <el-form-item label="全景视频" prop="url">
          <el-upload
            class="avatar-uploader"
            :action="$store.state.uploadingUrl"
            :show-file-list="false"
            accept=".mp4"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessVideo"
            :before-upload="beforeAvatarUploadVideo"
          >
            <video
              v-if="ruleForm.url"
              :src="ruleForm.url"
              width="100%"
              height="100%"
              controls
            >
              <source src="movie.mp4" type="video/mp4" />
            </video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              多个清晰度，请上传多个文件
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="全景音频" prop="url">
          <el-upload
            class="avatar-uploader"
            :action="$store.state.uploadingUrl"
            :show-file-list="false"
            accept=".mp4"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessVideo"
            :before-upload="beforeAvatarUploadVideo"
          >
            <video
              v-if="ruleForm.url"
              :src="ruleForm.url"
              width="100%"
              height="100%"
              controls
            >
              <source src="movie.mp4" type="video/mp4" />
            </video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              ios下会出现画面没有声音的情况，请上传一个mp3或m4a格式音频文件
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
export default {
  components: {
    commonTable,
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        name: "",
        options: "",
        coverImg: "",
        details: "",
        url: "",
      },
      rules: {},
      options: [
        {
          value: "选项1",
          label: "自定义媒体页",
        },
        {
          value: "选项2",
          label: "短视频",
        },
      ],
      value: "",
      tableData: [],
      pageSize: "",
      currentPage: "",
      total:'',
      loading: false, //表格加载
      isUpdate: true,
      fileList:[]
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleAvatarSuccessCoverImg(res, file, fileList) {
      this.fileList = fileList;
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.coverImg = `https://cdn-oss.jquen.com/${res.data}`;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.coverImg = "";
      this.fileList = [];
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if (this.isUpdate) {
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    /**@method 上传成功 */
    handleAvatarSuccessVideo(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.isUpdate = true;
      this.ruleForm.url = `https://cdn-oss.jquen.com/${res.data}`;
    },
    beforeAvatarUploadVideo(file) {
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error("zip压缩包大小不能超过 50MB!");
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}
::v-deep .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
::v-deep .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
::v-deep .disabled .el-upload--picture-card {
  display: none;
}
</style>